const laudableMixin = () => ({
  methods: {
    isLaudable(index = null) {
      const isExam = this?.attendance?.type === 'EXAM' || this.fileType === 'exam' || this?.forms[index]?.distinct_type === 'exam'
      return isExam && (!this.groupFiles || index === 0)
    },
    async createMedicalReportFile(fileId, form, index) {
      if (!this.isLaudable(index) || !form.laudable) return
      const responsiblesIds = form.selectedResponsibles.map(el => el.id).filter(el => el !== null)
      try {
        await this.api.createOrUpdateMedicalReportFile(fileId, form.procedure_id, 'PENDING', responsiblesIds, null)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  }
});

export default laudableMixin;